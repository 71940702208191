import Suggestion from '@/data/suggestions/common/suggestion'
import languageSuggestionFormatter from './language-suggestion-formatter'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import educationSuggestionFormatter from './education-suggestion-formatter'
import workExpSuggestionFormatter from './work-exp-suggestion-formatter'
import foreignWorkExpSuggestionFormatter from './foreign-work-exp-suggestion-formatter'
import coqSuggestionFormatter from './coq-suggestion-formatter'
import pnpSuggestionFormatter from './pnp-suggestion-formatter'
import canadianEducationSuggestionFormatter from './canadian-education-suggestion-formatter'
import jobOfferSuggestionFormatter from './job-offer-suggestion-formatter'
import ageSuggestionFormatter from './age-suggestion-formatter'

export default class SuggestionFormatter {
  /**
   * Format the given suggestion in order to be displayed to the user.
   *
   * @param profile Current profile.
   * @param suggestion The suggestion to render.
   */
  static format(profile: EditableProfile, suggestion: Suggestion): string {
    switch (suggestion.fieldId) {
      case 'age':
        return ageSuggestionFormatter(profile, suggestion)
      case 'language':
      case 'spouseLanguage':
        return languageSuggestionFormatter(profile, suggestion)
      case 'education':
      case 'spouseEducation':
        return educationSuggestionFormatter(profile, suggestion)
      case 'workExp':
      case 'spouseWorkExp':
        return workExpSuggestionFormatter(profile, suggestion)
      case 'foreignWorkExp':
        return foreignWorkExpSuggestionFormatter(profile, suggestion)
      case 'coq':
        return coqSuggestionFormatter(profile, suggestion)
      case 'pnp':
        return pnpSuggestionFormatter(profile, suggestion)
      case 'canadianEducation':
        return canadianEducationSuggestionFormatter(profile, suggestion)
      case 'jobOffer':
        return jobOfferSuggestionFormatter(profile, suggestion)
      default:
        return 'FORMATTER NOT IMPLEMENTED'
    }
  }
}
