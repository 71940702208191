import EditableProfile from '../entity/profile/editable-profile.entity'
import Suggestion from './common/suggestion'
import PointCalculator, { PointCalculatorResult } from '../point-calculator/point-calculator'

export default class AgeSuggestionGenerator {
  /**
   * Return true if the user should be warned about losing points due to aging.
   */
  static shouldShowAgeSuggestion(profile: EditableProfile): boolean {
    return !!profile.age && profile.age >= 29 && profile.age < 45
  }

  /**
   * Generate an age suggestion to warn the user that they will lose points
   * every year due to aging.
   */
  static generate(profile: EditableProfile, points: PointCalculatorResult): Suggestion | null {
    if (this.shouldShowAgeSuggestion(profile)) {
      const oldAge = profile.age!
      profile.age = oldAge + 1
      const updatedPoints = PointCalculator.calculatePoints(profile)
      profile.age = oldAge

      const pointCategory = points.categoryA.age

      return {
        fieldId: 'age',
        points: pointCategory.points,
        maxPoints: pointCategory.maxPoints,
        forSpouse: false,
        difficulty: -1,
        pointImprovement: updatedPoints.totalPoints - points.totalPoints,
        improvement: null,
      }
    } else {
      return null
    }
  }
}
