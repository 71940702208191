
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/9.png'),
      w: 981,
      h: 651,
      title: '1. Read the Terms and Conditions. Click I Accept.',
    },
  ]
  images2: GalleryImage[] = [
    {
      src: require('./img/10.png'),
      w: 988,
      h: 668,
      title: '2. Enter your information. Click Continue.',
    },
  ]
  images3: GalleryImage[] = [
    {
      src: require('./img/12.png'),
      w: 846,
      h: 666,
      title: '3. Create your security questions. Click Continue.',
    },
  ]
  images4: GalleryImage[] = [
    {
      src: require('./img/13.png'),
      w: 805,
      h: 715,
      title: "4. Your account is ready. This is your account's home page.",
    },
  ]
}
