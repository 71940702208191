
import Vue from 'vue'
import Component from 'vue-class-component'
import PoolDataRepository from '@/data/repository/pool-data.repository'
import { ProfileTypes } from '@/store/modules/profile.module'
import { Watch, Prop } from 'vue-property-decorator'
import LocalStorage from '@/data/util/local-storage'
import ImprovementFieldId from '@/data/suggestions/common/improvement-field-id'
import store from '@/store/store'
import debounce from 'lodash.debounce'
import PointCalculator from '@/data/point-calculator/point-calculator'
import AgeSuggestionGenerator from '@/data/suggestions/age-suggestion-generator'
import { SuggestionFilter } from '@/data/suggestions/common/suggestion-filter'
import { PointImprovementGuideTypes } from '@/store/modules/point-improvement-guide.module'

const LS_KEY_IMPROVEMENT_GUIDE_SETTINGS = 'app_improvementGuideSettings'

/**
 * Displays improvement guide settings, which allow the user to change suggestion generator
 * filters and target points.
 *
 * @param {String} message An error message to show below all the settings.
 */
@Component
export default class ImprovementGuideSettings extends Vue {
  @Prop({ type: String })
  message?: string

  filters: SuggestionFilter[] = [
    { fieldId: 'age', isEnabled: true },
    { fieldId: 'language', isEnabled: true },
    { fieldId: 'education', isEnabled: true },
    { fieldId: 'workExp', isEnabled: true },
    { fieldId: 'foreignWorkExp', isEnabled: true },
    { fieldId: 'spouseEducation', isEnabled: true, forSpouse: true },
    { fieldId: 'spouseLanguage', isEnabled: true, forSpouse: true },
    { fieldId: 'spouseWorkExp', isEnabled: true, forSpouse: true },
    { fieldId: 'coq', isEnabled: true },
    { fieldId: 'canadianEducation', isEnabled: true },
    { fieldId: 'jobOffer', isEnabled: true },
    { fieldId: 'pnp', isEnabled: true },
  ]

  isFiltersInitialized = false
  targetPoints = ''

  maxPointsRule = (input: string) => {
    const numberValue = Number(input)
    if (isNaN(numberValue)) {
      return 'Invalid value'
    } else if (numberValue > PointCalculator.MAX_CRS_POINTS) {
      return 'Maximum possible points is 1200'
    } else {
      return true
    }
  }

  mounted() {
    this.initFilters()
    this.onSavedTargetPointsChange()
  }

  get enabledFilters(): SuggestionFilter[] {
    const profile = store.state.profile.profile
    if (profile.hasSpouse && AgeSuggestionGenerator.shouldShowAgeSuggestion(profile)) {
      return this.filters
    } else {
      return this.filters.filter(it => {
        return (
          (profile.hasSpouse || !it.forSpouse) &&
          (it.fieldId !== 'age' || AgeSuggestionGenerator.shouldShowAgeSuggestion(profile))
        )
      })
    }
  }

  private get savedTargetPoints(): number {
    return store.state.pointImprovementGuide.targetPoints
  }

  private initFilters() {
    const savedFilters = LocalStorage.getItem(
      LS_KEY_IMPROVEMENT_GUIDE_SETTINGS
    ) as SuggestionFilter[]

    if (savedFilters) {
      savedFilters.forEach(filter => {
        const localFilter = this.filters.find(it => it.fieldId === filter.fieldId)
        if (localFilter) {
          localFilter.isEnabled = filter.isEnabled
        }
      })
    }

    this.isFiltersInitialized = true
    this.$emit('filters-change', this.filters)
  }

  onFilterChange() {
    this.$emit('filters-change', this.filters)
    LocalStorage.putItem(LS_KEY_IMPROVEMENT_GUIDE_SETTINGS, this.filters)
  }

  @Watch('targetPoints')
  private onTargetPointsChange() {
    const numberValue = Number(this.targetPoints) || 0
    this.$emit('target-change', numberValue)
    this.$store.commit(PointImprovementGuideTypes.mutations.setTargetPoints, numberValue)
  }

  @Watch('savedTargetPoints')
  private onSavedTargetPointsChange() {
    this.targetPoints = this.savedTargetPoints.toString()
  }
}
