import FieldImprovementCalculator from '../../common/field-improvement-calculator'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCalculator, { PointCalculatorResult } from '@/data/point-calculator/point-calculator'
import Suggestion from '../../common/suggestion'
import ImprovementFieldId from '../../common/improvement-field-id'
import { Profile } from 'shared-entities'

/**
 * Constructor parameters.
 */
interface Params {
  /** The field of the profile to be improved */
  profileField: keyof Profile
  /** The point category to which the profile field belongs. */
  category: 'categoryC' | 'categoryD'
  /** Difficulty of setting the value of the field to true */
  difficulty: number
}

/**
 * A generic improvement calculator for boolean profile fields.
 */
export default abstract class BooleanImprovementCalculator implements FieldImprovementCalculator {
  constructor(private readonly params: Params) {}

  abstract fieldId: ImprovementFieldId

  calculate(profile: EditableProfile, points: PointCalculatorResult): Suggestion | null {
    if (!profile[this.params.profileField]) {
      const improvedProfile = profile.clone() as any
      improvedProfile[this.params.profileField] = true
      const improvedPoints = PointCalculator.calculatePoints(improvedProfile)

      return {
        fieldId: this.fieldId,
        points: null,
        maxPoints: null,
        improvement: true,
        pointImprovement: improvedPoints.totalPoints - points.totalPoints,
        difficulty: this.params.difficulty,
        forSpouse: false,
      }
    }
    return null
  }

  consolidate(
    profile: EditableProfile,
    points: PointCalculatorResult,
    suggestions: Suggestion[]
  ): Suggestion | null {
    if (suggestions.length) {
      return suggestions[0]
    }
    return null
  }

  applySuggestion(profile: EditableProfile, suggestion: Suggestion) {
    const profileAsAny = profile as any
    profileAsAny[this.params.profileField] = suggestion.improvement
  }
}
