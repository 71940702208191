import Utils from '@/common/util/utils'
import EditableProfile from '../entity/profile/editable-profile.entity'
import PointCalculator, { PointCalculatorResult } from '../point-calculator/point-calculator'
import ImprovementFieldId from './common/improvement-field-id'
import FieldImprovementCalculator from './common/field-improvement-calculator'
import Suggestion, { SuggestionFieldId } from './common/suggestion'
import EducationImprovementCalculator from './improvements/education-improvement-calculator'
import LanguageImprovementCalculator from './improvements/language-improvement-calculator'
import SuggestionGroup, { SuggestionContainer } from './common/suggestion-group'
import WorkExpImprovementCalculator from './improvements/work-exp-improvement-calculator'
import ForeignWorkExpImprovementCalculator from './improvements/foreign-work-exp-improvement-calculator'
import CoqImprovementCalculator from './improvements/coq-improvement-calculator'
import PnpImprovementCalculator from './improvements/pnp-improvement-calculator'
import CanadianEducationImprovementCalculator from './improvements/canadian-education-improvement-calculator'
import JobOfferImprovementCalculator from './improvements/job-offer-improvement-calculator'
import SuggestionGeneratorResult from './common/suggestion-generator-result'
import AgeSuggestionGenerator from './age-suggestion-generator'
import { SuggestionFilter } from './common/suggestion-filter'

/**
 * Maps field IDs to a list of suggestions for that field. The list is
 * used in the end to consolidate suggestions for every field into a single suggestion.
 */
type SuggestionMap = {
  [key in ImprovementFieldId]?: Suggestion[]
}

/**
 * Suggestion filters keyed by their fieldId.
 */
type SuggestionFilterMap = {
  [key in SuggestionFieldId]?: SuggestionFilter
}

/**
 * FieldImprovementCalculators keyed by their fieldId for easy lookup.
 */
const CALCULATORS: { [key in ImprovementFieldId]: FieldImprovementCalculator } = {
  language: new LanguageImprovementCalculator(),
  education: new EducationImprovementCalculator(),
  workExp: new WorkExpImprovementCalculator(),
  foreignWorkExp: new ForeignWorkExpImprovementCalculator(),
  spouseLanguage: new LanguageImprovementCalculator(true),
  spouseEducation: new EducationImprovementCalculator(true),
  spouseWorkExp: new WorkExpImprovementCalculator(true),
  coq: new CoqImprovementCalculator(),
  pnp: new PnpImprovementCalculator(),
  canadianEducation: new CanadianEducationImprovementCalculator(),
  jobOffer: new JobOfferImprovementCalculator(),
}

/**
 * Array of calculators for easy iteration.
 */
const CALCULATORS_ARRAY = Object.values(CALCULATORS)

/**
 * [SuggestionContainer]s used to group generated suggestions by CRS point category.
 */
const SUGGESTION_CONTAINERS = {
  categoryA: { title: 'A', description: 'category.a' },
  categoryB: { title: 'B', description: 'category.b' },
  categoryC: { title: 'C', description: 'category.c' },
  categoryD: { title: 'D', description: 'category.d' },
}

const FIELD_ID_TO_SUGGESTION_CONTAINER: { [key in SuggestionFieldId]: SuggestionContainer } = {
  age: SUGGESTION_CONTAINERS.categoryA,
  language: SUGGESTION_CONTAINERS.categoryA,
  education: SUGGESTION_CONTAINERS.categoryA,
  workExp: SUGGESTION_CONTAINERS.categoryA,
  spouseLanguage: SUGGESTION_CONTAINERS.categoryB,
  spouseEducation: SUGGESTION_CONTAINERS.categoryB,
  spouseWorkExp: SUGGESTION_CONTAINERS.categoryB,
  foreignWorkExp: SUGGESTION_CONTAINERS.categoryC,
  coq: SUGGESTION_CONTAINERS.categoryC,
  pnp: SUGGESTION_CONTAINERS.categoryD,
  canadianEducation: SUGGESTION_CONTAINERS.categoryD,
  jobOffer: SUGGESTION_CONTAINERS.categoryD,
}

export default class SuggestionGenerator {
  /**
   * Generate point improvement suggestions for the given profile.
   *
   * @param profile The current profile.
   * @param targetPoints The desired number of CRS points.
   * @param filters A list of suggestion filters which restrict the considered
   *    [SuggestionFieldId]s.
   */
  static generate(
    profile: EditableProfile,
    targetPoints: number,
    filters: SuggestionFilter[]
  ): SuggestionGeneratorResult {
    const originalPoints = PointCalculator.calculatePoints(profile)
    let points = originalPoints
    const improvedProfile = profile.clone()

    // Turn the list of filters into a map for easier querying.
    const filtersMap: SuggestionFilterMap = {}
    filters.forEach(filter => {
      filtersMap[filter.fieldId] = filter
    })

    // Greedily choose the next best suggestion for the current profile, update
    // the cloned profile with that suggestion and continue in this fashion until
    // we reach the target points or we run out of suggestions.
    const suggestions: SuggestionMap = {}
    while (points.totalPoints < targetPoints) {
      const suggestion = this.nextSuggestion(improvedProfile, points, targetPoints, filtersMap)
      if (suggestion && suggestion.fieldId !== 'age') {
        const fieldId = suggestion.fieldId
        const calculator = CALCULATORS[fieldId]
        calculator.applySuggestion(improvedProfile, suggestion)
        if (!suggestions[fieldId]) {
          suggestions[fieldId] = []
        }
        suggestions[fieldId]!.push(suggestion)
        points = PointCalculator.calculatePoints(improvedProfile)
      } else {
        break
      }
    }

    /**
     * Consolidate and the generated suggestions and form the final result.
     */
    return this.consolidateSuggestions(
      profile,
      originalPoints,
      suggestions,
      targetPoints,
      filtersMap
    )
  }

  /**
   * Determine the next best suggestion to be applied to the given profile.
   *
   * @param profile The current profile state with all previously chosen suggestions applied.
   * @param points The result of CRS point calculation for the given profile.
   * @param targetPoints The desired number of CRS points.
   * @param filters The filters passed to [generate].
   */
  private static nextSuggestion(
    profile: EditableProfile,
    points: PointCalculatorResult,
    targetPoints: number,
    filters: SuggestionFilterMap
  ): Suggestion | null {
    // Iterate over the list of calculators, collect their suggestions, then choose
    // the best one.
    const suggestions: Suggestion[] = []
    CALCULATORS_ARRAY.forEach(calculator => {
      const filter = filters[calculator.fieldId]
      if (filter && !filter.isEnabled) {
        return
      }

      const batch = calculator.calculate(profile, points)
      if (batch) {
        if (Array.isArray(batch)) {
          Array.prototype.push.apply(suggestions, batch)
        } else {
          suggestions.push(batch)
        }
      }
    })

    return this.findBestSuggestion(suggestions, points, targetPoints)
  }

  /**
   * Select the best suggestion from the given list of suggestions.
   *
   * @param suggestions The list of suggestions.
   * @param points The result of CRS point calculation for the profile for which
   *    the suggestions are considered.
   * @param targetPoints The desired number of CRS points.
   */
  private static findBestSuggestion(
    suggestions: Suggestion[],
    points: PointCalculatorResult,
    targetPoints: number
  ): Suggestion | null {
    let bestSuggestion: Suggestion | null = null
    let bestScore = 0
    let isBestSuggestionSufficient = false

    // Find the suggestion with maximum score OR the least difficult suggestion that
    // reaches the target points if such suggestion exists.
    suggestions.forEach(suggestion => {
      const score = this.suggestionScore(suggestion)
      if (score <= 0) {
        return
      }

      const isSufficient = this.isSuggestionSufficient(suggestion, points, targetPoints)
      if (!bestSuggestion) {
        bestSuggestion = suggestion
        bestScore = score
        isBestSuggestionSufficient = isSufficient
      } else {
        const isEasier = suggestion.difficulty < bestSuggestion.difficulty
        if (isEasier && isSufficient) {
          bestSuggestion = suggestion
          bestScore = score
          isBestSuggestionSufficient = isSufficient
        } else if (
          score > bestScore &&
          (!isBestSuggestionSufficient || (isSufficient && isEasier))
        ) {
          bestSuggestion = suggestion
          bestScore = score
          isBestSuggestionSufficient = isSufficient
        }
      }
    })

    return bestSuggestion
  }

  /**
   * Determine suggestion score used when determining the best suggestion.
   */
  private static suggestionScore(suggestion: Suggestion): number {
    return suggestion.pointImprovement / suggestion.difficulty
  }

  /**
   * Determine if the given suggestion is sufficient to reach the target points
   * from the given points.
   */
  private static isSuggestionSufficient(
    suggestion: Suggestion,
    points: PointCalculatorResult,
    targetPoints: number
  ): boolean {
    return points.totalPoints + suggestion.pointImprovement >= targetPoints
  }

  /**
   * Consolidate the generated suggestions, filter unnecessary ones, and form the
   * final result.
   *
   * @param profile The original profile passed to [generate].
   * @param originalPoints The original result of CRS point calculation.
   * @param suggestionMap The map of generated suggestions.
   * @param targetPoints The desired number of CRS points.
   * @param filters The filters passed to [generate].
   */
  private static consolidateSuggestions(
    profile: EditableProfile,
    originalPoints: PointCalculatorResult,
    suggestionMap: SuggestionMap,
    targetPoints: number,
    filters: SuggestionFilterMap
  ): SuggestionGeneratorResult {
    let points = originalPoints
    const improvedProfile = profile.clone()
    const suggestionsList: Suggestion[] = []

    // Consolidate all suggestions so that suggestionsList contains
    // each SuggestionFieldId at most once.
    Utils.typedKeys(suggestionMap).forEach(fieldId => {
      const suggestions = suggestionMap[fieldId]
      const calculator = CALCULATORS[fieldId]
      const consolidated = calculator.consolidate(improvedProfile, points, suggestions!)
      if (consolidated) {
        calculator.applySuggestion(improvedProfile, consolidated)
        points = PointCalculator.calculatePoints(improvedProfile)

        suggestionsList.push(consolidated)
      }
    })

    const { improvedPoints, suggestions } = this.filterSuggestions(
      profile,
      originalPoints,
      suggestionsList,
      targetPoints
    )

    if (!filters.age || filters.age.isEnabled) {
      const ageSuggestion = AgeSuggestionGenerator.generate(profile, originalPoints)
      if (ageSuggestion) {
        suggestions.splice(0, 0, ageSuggestion)
      }
    }

    return {
      improvedPoints,
      suggestionGroups: this.groupSuggestions(suggestions),
    }
  }

  /**
   * Filter unnecessary suggestions from the given list of suggestions.
   *
   * Since we use a greedy algorithm to choose suggestions, there can be cases
   * where at first we choose some suggestions with higher score but lower pointImprovement, which
   * do not get us to the target points. After forming the final list of suggestions
   * these initial winners could no longer be needed because the later suggestions have
   * enough pointImprovement to reach target points.
   *
   * @param profile The original profile.
   * @param originalPoints The original CRS points.
   * @param suggestions The list of suggestions to filter.
   * @param targetPoints The desired CRS points.
   */
  private static filterSuggestions(
    profile: EditableProfile,
    originalPoints: PointCalculatorResult,
    suggestions: Suggestion[],
    targetPoints: number
  ): { improvedPoints: PointCalculatorResult; suggestions: Suggestion[] } {
    let points = originalPoints
    const improvedProfile = profile.clone()

    // We sort the list of suggestions by decending score, then try to apply
    // suggestions in this order until we reach the one which gets us to target points.
    //
    // (1) If such suggestion is found, then it's added to the current filtered list
    // and the result is returned.
    //
    // (2) Otherwise, the suggestion at `startIndex` is applied,
    // the iteration is restarted from `startIndex + 1`, and so on until the list of
    // suggestions is exhausted or we get to case (1).

    const filteredList: Suggestion[] = []
    suggestions.sort((a, b) => this.suggestionScore(b) - this.suggestionScore(a))

    let startIndex = 0
    while (startIndex < suggestions.length && points.totalPoints < targetPoints) {
      for (let i = startIndex; i < suggestions.length; i++) {
        const suggestion = suggestions[i]

        const improvedProfileClone = improvedProfile.clone()
        this.applySuggestion(improvedProfileClone, suggestion)
        const improvedPoints = PointCalculator.calculatePoints(improvedProfileClone)

        if (improvedPoints.totalPoints >= targetPoints) {
          filteredList.push(suggestion)
          return {
            improvedPoints,
            suggestions: filteredList,
          }
        }
      }

      const suggestion = suggestions[startIndex]
      this.applySuggestion(improvedProfile, suggestion)
      points = PointCalculator.calculatePoints(improvedProfile)
      filteredList.push(suggestions[startIndex])
      startIndex++
    }

    return {
      improvedPoints: points,
      suggestions: filteredList,
    }
  }

  /**
   * Apply the given suggestion to the given profile.
   */
  private static applySuggestion(profile: EditableProfile, suggestion: Suggestion) {
    if (suggestion.fieldId !== 'age') {
      const calculator = CALCULATORS[suggestion.fieldId]
      calculator.applySuggestion(profile, suggestion)
    }
  }

  /**
   * Group the list of suggestions within the corresponding [SuggestionContainer]s.
   */
  private static groupSuggestions(suggestions: Suggestion[]): SuggestionGroup[] {
    const suggestionGroups: { [key: string]: SuggestionGroup } = {}
    for (let i = 0; i < suggestions.length; i++) {
      const suggestion = suggestions[i]
      const suggestionContainer = FIELD_ID_TO_SUGGESTION_CONTAINER[suggestion.fieldId]
      if (!suggestionGroups[suggestionContainer.title]) {
        suggestionGroups[suggestionContainer.title] = {
          ...suggestionContainer,
          suggestions: [suggestion],
        }
      } else {
        suggestionGroups[suggestionContainer.title].suggestions.push(suggestion)
      }
    }

    return Utils.typedValues(suggestionGroups).sort((a, b) => (a.title < b.title ? -1 : 1))
  }
}
