import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Suggestion from '@/data/suggestions/common/suggestion'
import { EducationLevel } from 'shared-entities'
import { EDUCATION_LEVEL_OPTIONS } from '@/data/point-calculator/categories/core/education-point-calculator'
import I18n from '@/i18n/i18n'

export default function(profile: EditableProfile, suggestion: Suggestion): string {
  const spouseString = suggestion.forSpouse ? "spouse's " : ''
  const educationLevel = suggestion.improvement as EducationLevel
  if (educationLevel) {
    const educationLevelOption = EDUCATION_LEVEL_OPTIONS.find(it => it.id === educationLevel)
    if (educationLevelOption) {
      return (
        `<p>Improve your ${spouseString}education level at least to:</p>` +
        `<strong>${I18n.$t(educationLevelOption.title)}</strong>.`
      )
    }
  }
  return ''
}
