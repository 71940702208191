import PointCalculatorSectionId from './point-calculator-section-id'

/**
 * Colors for different parent point categories.
 */
export default function categoryColor(sectionId: PointCalculatorSectionId): string {
  switch (sectionId) {
    case 'A':
      return '#fe2042'
    case 'B':
      return '#f8c309'
    case 'C':
      return '#6c63ff'
    case 'D':
      return '#40bfe7'
    default:
      return '#dadada'
  }
}
