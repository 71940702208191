
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class DaysLeft extends Vue {
  @Prop({ type: [Number, String], default: null })
  days!: number | string | null
}
