import { GuideName } from 'shared-entities'
import { GuideValidityPeriod } from './guide-validity-period'
import store from '@/store/store'
import dayjs from 'dayjs'

/**
 * Calculates guide deadlines
 */
export default class GuideDeadlineCalculator {
  /**
   * Calculate the number of days left until the deadline of the guide specified
   * by the given guide name. If guide is not initialized or if the guide's date field
   * is not set, then null is returned.
   */
  static calculateDaysLeft(name: GuideName): number | null {
    const guideProgress = store.state.profile.guideProgress
    const progress = guideProgress[name]
    if (progress) {
      const validityPeriod = this.getGuideDateValidityPeriod(name)

      if (progress.date) {
        const date = dayjs(progress.date)
        // Add extra minute so that the difference is calculated properly below.
        const adjustedDate = date.add(validityPeriod.count, validityPeriod.unit).add(1, 'minute')
        const now = dayjs()
        return adjustedDate.diff(now, 'day')
      }
    }

    return null
  }

  /**
   * Return the validity period of the guide specified by the given guide name.
   */
  static getGuideDateValidityPeriod(name: GuideName): GuideValidityPeriod {
    switch (name) {
      case 'ircc':
        return {
          count: 1,
          unit: 'year',
        }
      case 'ita':
        return {
          count: 60,
          unit: 'day',
        }
      case 'visa':
        return {
          count: 30,
          unit: 'day',
        }
      case 'landing':
        return {
          count: 0,
          unit: 'day',
        }
    }
  }
}
