import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { LangImprovement, LangScoreImprovement } from '../language-improvement-calculator'
import { LanguageTestScores, LANGUAGE_SKILLS } from 'shared-entities'
import Utils from '@/common/util/utils'
import Suggestion from '../../common/suggestion'

/**
 * Common utility functions for working with language improvements.
 *
 * Previously was part of LanguageImprovementCalculator, then refactor in separate class
 * to implement FrenchBonusCalculator, which was then removed. Decided to leave
 * utility methods like this just in case.
 */
export default class LanguageImprovementUtils {
  /**
   * Consolidate suggestions into a single improvement.
   */
  static consolidateImprovements(
    profile: EditableProfile,
    suggestions: Suggestion[]
  ): LangImprovement | null {
    if (suggestions.length) {
      const consolidated: LangImprovement = {}
      const oldFirstLangScores = profile.getFirstLanguageClbScores()
      const oldSecondLangScores = profile.getSecondLanguageClbScores()

      suggestions.forEach(suggestion => {
        const improvement = suggestion.improvement as LangImprovement
        if (improvement.firstLangTestType) {
          consolidated.firstLangTestType = improvement.firstLangTestType
        } else if (improvement.secondLangTestType) {
          consolidated.secondLangTestType = improvement.secondLangTestType
        }

        this.addScores(consolidated, improvement, oldFirstLangScores, 'firstLangScores')
        this.addScores(consolidated, improvement, oldSecondLangScores, 'secondLangScores')
      })

      return consolidated
    } else {
      return null
    }
  }

  private static addScores(
    consolidated: LangImprovement,
    improvement: LangImprovement,
    oldScores: LanguageTestScores | null,
    scoresField: 'firstLangScores' | 'secondLangScores'
  ) {
    if (improvement[scoresField]) {
      const consolidatedScores: LangScoreImprovement = consolidated[scoresField] || {}

      Utils.typedEntries(improvement[scoresField]).forEach(([skill, score]) => {
        const consolidatedScore = consolidatedScores[skill]
        if (
          (!oldScores || oldScores[skill] < score) &&
          (!consolidatedScore || consolidatedScore < score)
        ) {
          consolidatedScores[skill] = score
        }
      })

      if (!Utils.isObjectEmpty(consolidatedScores)) {
        consolidated[scoresField] = consolidatedScores
      }
    }
  }

  /**
   * Apply language improvement to the given profile.
   */
  static applyImprovement(profile: EditableProfile, improvement: LangImprovement) {
    if (improvement.firstLangTestType) {
      profile.firstLangTestType = improvement.firstLangTestType
    }
    if (improvement.secondLangTestType) {
      profile.secondLangTestType = improvement.secondLangTestType
    }
    if (improvement.firstLangScores && profile.firstLangTestType) {
      const oldFirstLangScores = profile.getFirstLanguageClbScores()
      Utils.typedEntries(improvement.firstLangScores).forEach(([skill, value]) => {
        if (value && (!oldFirstLangScores || oldFirstLangScores[skill] < value)) {
          profile.setFirstLanguageSkillScore({ skill, value })
        }
      })
    }
    if (improvement.secondLangScores && profile.secondLangTestType) {
      const oldSecondLangScores = profile.getSecondLanguageClbScores()
      Utils.typedEntries(improvement.secondLangScores).forEach(([skill, value]) => {
        if (value && (!oldSecondLangScores || oldSecondLangScores[skill] < value)) {
          profile.setSecondLanguageSkillScore({ skill, value })
        }
      })
    }
  }
}
