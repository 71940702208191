import Utils from '@/common/util/utils'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import OfficialLanguage from '@/data/entity/profile/official-language'
import PointCalculator, { PointCalculatorResult } from '@/data/point-calculator/point-calculator'
import {
  LanguageSkill,
  LanguageTestScores,
  LanguageTestType,
  LANGUAGE_SKILLS,
} from 'shared-entities'
import FieldImprovementCalculator from '../common/field-improvement-calculator'
import Suggestion from '../common/suggestion'
import LanguageImprovementUtils from './util/language-improvement-utils'
import ImprovementFieldId from '../common/improvement-field-id'
import ImprovementDifficulty from './util/improvement-difficulty'

const MIN_CLB_SCORE = 4
const SECOND_LANG_MIN_CLB_SCORE = 5
const SPOUSE_MIN_CLB_SCORE = 5
const MAX_CLB = 10

type Lang = 'first' | 'second'

/** Language test scores for skills that need to be improved. */
export type LangScoreImprovement = {
  [key in LanguageSkill]?: number
}

/**
 * An object to be set to the `improvement` field of the generated Suggestion.
 */
export interface LangImprovement {
  /**
   * The first/second language test type to be set.
   * Defined if previously the user had not chosen the first/second language test.
   */
  firstLangTestType?: LanguageTestType
  secondLangTestType?: LanguageTestType

  /**
   * The score improvements for first/second language.
   */
  firstLangScores?: LangScoreImprovement
  secondLangScores?: LangScoreImprovement
}

export default class LanguageImprovementCalculator implements FieldImprovementCalculator {
  constructor(private readonly forSpouse?: boolean) {}

  get fieldId(): ImprovementFieldId {
    return this.forSpouse ? 'spouseLanguage' : 'language'
  }

  calculate(profile: EditableProfile, points: PointCalculatorResult): Suggestion[] | null {
    if (this.forSpouse && !profile.hasSpouse) {
      return null
    }

    let improvements = this.langImprovement(profile, points, 'first')
    if (!improvements || !improvements.length) {
      improvements = this.langImprovement(profile, points, 'second')
    }
    return improvements
  }

  /**
   * Calculate suggestions for a single language (first or second).
   */
  private langImprovement(
    profile: EditableProfile,
    points: PointCalculatorResult,
    lang: Lang
  ): Suggestion[] | null {
    const scores = this.getLanguageScores(profile, lang)
    if (scores) {
      return LANGUAGE_SKILLS.map(skill => {
        if (scores[skill] < MAX_CLB) {
          return this.skillImprovement(profile, points, skill, scores, lang)
        }
        return null
      }).filter(Utils.hasValue)
    } else {
      const minSuggestion = this.minLanguageImprovement(profile, points, lang)
      return minSuggestion ? [minSuggestion] : null
    }
  }

  /**
   * Get the CLB scores for either first or second language (as determined by the `lang` parameter)
   * of the given profile.
   */
  private getLanguageScores(profile: EditableProfile, lang: Lang): LanguageTestScores | null {
    const theProfile = this.getProfileObject(profile)
    return lang === 'first'
      ? theProfile.getFirstLanguageClbScores()
      : theProfile.getSecondLanguageClbScores()
  }

  /**
   * Get the profile object to be manipulated (either the main profile or the spouse's profile).
   */
  private getProfileObject(profile: EditableProfile): EditableProfile {
    return this.forSpouse ? profile.spouse : profile
  }

  /**
   * Calculate the suggestion to improve a single skill.
   *
   * @param profile The original profile.
   * @param points The CRS points for the original profile.
   * @param skill The skill to be improved.
   * @param scores The original test scores.
   * @param lang The language (first or second).
   */
  private skillImprovement(
    profile: EditableProfile,
    points: PointCalculatorResult,
    skill: LanguageSkill,
    scores: LanguageTestScores,
    lang: Lang
  ): Suggestion | null {
    const field = lang === 'first' ? 'firstLangScores' : 'secondLangScores'
    let suggestion: Suggestion | null = null
    let increment = 1
    while (!suggestion && scores[skill] + increment <= MAX_CLB) {
      const improvedScore = Math.max(scores[skill] + increment, this.getMinClb(lang))
      const improvement: LangImprovement = {
        [field]: {
          [skill]: improvedScore,
        },
      }

      suggestion = this.improvementToSuggestion(profile, points, improvement)
      increment++
    }

    return suggestion
  }

  /**
   * Return the minimum language improvement suggestion, for cases when
   * no language test was set at all.
   */
  private minLanguageImprovement(
    profile: EditableProfile,
    points: PointCalculatorResult,
    lang: Lang
  ): Suggestion | null {
    const testTypeField = lang === 'first' ? 'firstLangTestType' : 'secondLangTestType'
    const scoresField = lang === 'first' ? 'firstLangScores' : 'secondLangScores'

    let testType: LanguageTestType = 'IELTS'
    const theProfile = this.getProfileObject(profile)

    if (lang === 'first') {
      const secondLangTest = theProfile.secondLanguageTest
      if (secondLangTest) {
        testType = secondLangTest.language === OfficialLanguage.ENGLISH ? 'TEF' : 'IELTS'
      }
    } else {
      const firstLangTest = theProfile.firstLanguageTest
      if (firstLangTest) {
        testType = firstLangTest.language === OfficialLanguage.ENGLISH ? 'TEF' : 'IELTS'
      }
    }

    const minClb = this.getMinClb(lang)
    const improvement: LangImprovement = {
      [testTypeField]: testType,
      [scoresField]: { r: minClb, w: minClb, l: minClb, s: minClb },
    }

    return this.improvementToSuggestion(profile, points, improvement)
  }

  /**
   * Map a LangImprovement object to a Suggestion.
   *
   * @param profile The original profile, without the improvement applied.
   * @param points The CRS points for the original profile.
   * @param improvement The language improvement.
   */
  private improvementToSuggestion(
    profile: EditableProfile,
    points: PointCalculatorResult,
    improvement: LangImprovement
  ): Suggestion | null {
    const improvedProfile = profile.clone()
    this.applyImprovement(improvedProfile, improvement)
    const improvedPoints = PointCalculator.calculatePoints(improvedProfile)

    const pointImprovement = improvedPoints.totalPoints - points.totalPoints
    if (pointImprovement <= 0) {
      return null
    } else {
      const pointCategory = this.forSpouse ? points.categoryB.language : points.categoryA.language

      return {
        fieldId: this.forSpouse ? 'spouseLanguage' : 'language',
        difficulty: this.calculateDiffuculty(profile, improvement),
        points: pointCategory.points,
        maxPoints: pointCategory.maxPoints,
        pointImprovement,
        improvement,
        forSpouse: !!this.forSpouse,
      }
    }
  }

  private calculateDiffuculty(profile: EditableProfile, improvement: LangImprovement): number {
    return ImprovementDifficulty.language(this.getProfileObject(profile), improvement)
  }

  /**
   * Return the minimum CLB score which awards non-zero CRS points.
   */
  private getMinClb(lang: Lang): number {
    if (this.forSpouse) {
      return SPOUSE_MIN_CLB_SCORE
    } else {
      return lang === 'first' ? MIN_CLB_SCORE : SECOND_LANG_MIN_CLB_SCORE
    }
  }

  consolidate(
    profile: EditableProfile,
    points: PointCalculatorResult,
    suggestions: Suggestion[]
  ): Suggestion | null {
    const consolidated = LanguageImprovementUtils.consolidateImprovements(
      this.getProfileObject(profile),
      suggestions
    )

    if (consolidated) {
      return this.improvementToSuggestion(profile, points, consolidated)
    }
    return null
  }

  applySuggestion(profile: EditableProfile, suggestion: Suggestion) {
    this.applyImprovement(profile, suggestion.improvement)
  }

  private applyImprovement(profile: EditableProfile, improvement: LangImprovement) {
    LanguageImprovementUtils.applyImprovement(this.getProfileObject(profile), improvement)
  }
}
