
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import categoryColor from './point-calculator-section-color'
import PointCalculatorSectionId from './point-calculator-section-id'

@Component
export default class PointCategoryHeader extends Vue {
  @Prop({ type: String, required: true })
  sectionId!: PointCalculatorSectionId
  @Prop({ type: String, required: true })
  description!: string
  @Prop({ type: Boolean, default: false })
  expandable!: boolean
  @Prop({ type: Boolean, default: false })
  isCollapsed!: boolean

  get titleStyle() {
    return {
      backgroundColor: categoryColor(this.sectionId),
    }
  }

  toggleCollapsed() {
    this.$emit('toggle')
  }
}
