import NumericImprovementCalculator from './util/numeric-improvement-calculator'
import ImprovementFieldId from '../common/improvement-field-id'
import ImprovementDifficulty from './util/improvement-difficulty'

export default class ForeignWorkExpImprovementCalculator extends NumericImprovementCalculator {
  fieldId: ImprovementFieldId = 'foreignWorkExp'

  constructor() {
    super({
      profileField: 'foreignWorkExp',
      category: 'categoryC',
      forSpouse: false,
      difficultyMap: ImprovementDifficulty.foreignWorkExp,
      minValue: 1,
      maxValue: 5,
    })
  }
}
