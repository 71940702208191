import { DifficultyMap } from './numeric-improvement-calculator'
import { EducationLevel, NocGroup, LanguageTestScores, LANGUAGE_SKILLS } from 'shared-entities'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { LangImprovement, LangScoreImprovement } from '../language-improvement-calculator'

/**
 * Defines difficulty objects for all possible improvements for easy modification in one place.
 *
 * Difficulty objects can be of three types:
 *  - numeric constants (for boolean profile fields);
 *  - DifficultyMap objects (for numeric profile fields);
 *  - functions with arbitrary signature which calculate difficulty based on parameters
 *    that depend on improvement type.
 */
export default class ImprovementDifficulty {
  static canadianEducation: DifficultyMap = {
    1: 40,
    3: 100,
  }

  static coq = 80

  static education: DifficultyMap = {
    [EducationLevel.LESS_THAN_SECONDARY]: -1,
    [EducationLevel.SECONDARY]: 50,
    [EducationLevel.ONE_YEAR_DEGREE]: 80,
    [EducationLevel.TWO_YEAR_DEGREE]: 80,
    [EducationLevel.BACHELORS_DEGREE]: 100,
    [EducationLevel.TWO_DEGREES]: 50,
    [EducationLevel.MASTERS_DEGREE]: 80,
    [EducationLevel.PHD]: 200,
  }

  static foreignWorkExp: DifficultyMap = {
    1: 20,
    2: 20,
    3: 20,
    4: 20,
    5: 20,
    6: 20,
  }

  static jobOffer(jobOffer: NocGroup): number {
    return jobOffer === NocGroup.NOC_00 ? 300 : 50
  }

  static language(profile: EditableProfile, improvement: LangImprovement): number {
    return (
      this.langDifficulty(
        'first',
        profile.getFirstLanguageClbScores(),
        improvement.firstLangScores
      ) +
      this.langDifficulty(
        'second',
        profile.getSecondLanguageClbScores(),
        improvement.secondLangScores
      )
    )
  }

  private static langDifficulty(
    lang: 'first' | 'second',
    oldScores: LanguageTestScores | null,
    improvement?: LangScoreImprovement
  ): number {
    if (improvement) {
      const scores = oldScores || { l: 0, r: 0, w: 0, s: 0 }
      let difficulty = 0

      let modifier = 1

      // Reduce the difficulty by the modifier factor in following cases:
      //  - the first language is mandatory, so improving it should be suggested more readily.
      //  - if the user knows the second language, then improving it becomes easier.
      if (lang === 'first' || oldScores) {
        modifier = 0.2
      }

      LANGUAGE_SKILLS.forEach(skill => {
        const oldScore = scores[skill]
        const newScore = improvement[skill]

        // This assumes that the difficulty of improving a CLB score grows linearly
        // with the score, so for example if improving it from 0 to 1 costs 1, then
        // improving it from 1 to 2 costs 2, from 2 to 3 costs 3 etc.
        //
        // This means that to calculate the difficulty for a skill whose value is currently
        // M and needs to be increased to N, we compute a sum(x from m to n), which can be
        // calculated as sum(x from 1 to n) - sum(x from 1 to m), and
        // each sum can be calculated with sum(x from 1 to a) = a * (a + 1) / 2
        //
        // Finally we multiply the result by the modifier factor.
        if (newScore && newScore > oldScore) {
          const n = newScore
          const m = oldScore
          difficulty += ((n * (n + 1)) / 2 - (m * (m + 1)) / 2) * modifier
        }
      })
      return difficulty
    } else {
      return 0
    }
  }

  static pnp = 800

  static workExp: DifficultyMap = {
    1: 150,
    2: 20,
    3: 20,
    4: 20,
    5: 20,
    6: 20,
  }
}
