import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Suggestion from '@/data/suggestions/common/suggestion'
import I18n from '@/i18n/i18n'

export default function(profile: EditableProfile, suggestion: Suggestion): string {
  const age = profile.age!
  return (
    '<p>We recommend that you apply soon. Every year ' +
    'you will lose some points until you reach 45 years of age.</p>' +
    `<p class="mb-0">You will lose <strong>${Math.abs(suggestion.pointImprovement)}</strong> ` +
    'points when you reach ' +
    `<strong>${I18n.$tc('plural.year', age + 1, { count: age + 1 })}</strong> of age.</p>`
  )
}
