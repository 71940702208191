import NumericImprovementCalculator from './util/numeric-improvement-calculator'
import ImprovementFieldId from '../common/improvement-field-id'
import ImprovementDifficulty from './util/improvement-difficulty'

export default class CanadianEducationImprovementCalculator extends NumericImprovementCalculator {
  fieldId: ImprovementFieldId = 'canadianEducation'

  constructor() {
    super({
      profileField: 'canadianEducation',
      category: 'categoryD',
      forSpouse: false,
      difficultyMap: ImprovementDifficulty.canadianEducation,
      minValue: 1,
      maxValue: 3,
    })
  }
}
