import FieldImprovementCalculator from '../common/field-improvement-calculator'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCalculator, { PointCalculatorResult } from '@/data/point-calculator/point-calculator'
import Suggestion from '../common/suggestion'
import { NocGroup } from 'shared-entities'
import ListUtils from '@/common/util/list-utils'
import ImprovementFieldId from '../common/improvement-field-id'
import ImprovementDifficulty from './util/improvement-difficulty'

export default class JobOfferImprovementCalculator implements FieldImprovementCalculator {
  fieldId: ImprovementFieldId = 'jobOffer'

  calculate(profile: EditableProfile, points: PointCalculatorResult): Suggestion | null {
    const oldJobOffer = profile.jobOffer
    if (oldJobOffer === NocGroup.NOC_00) {
      return null
    } else if (
      oldJobOffer === NocGroup.NOC_0 ||
      oldJobOffer === NocGroup.NOC_A ||
      oldJobOffer === NocGroup.NOC_B
    ) {
      profile.jobOffer = NocGroup.NOC_00
    } else {
      profile.jobOffer = NocGroup.NOC_0
    }

    const suggestion = this.createSuggestion(profile, points, profile.jobOffer)
    profile.jobOffer = oldJobOffer
    return suggestion
  }

  private createSuggestion(
    profile: EditableProfile,
    points: PointCalculatorResult,
    jobOffer: NocGroup
  ): Suggestion | null {
    const improvedPoints = PointCalculator.calculatePoints(profile)
    const pointImprovement = improvedPoints.totalPoints - points.totalPoints

    if (pointImprovement <= 0) {
      return null
    } else {
      return {
        fieldId: 'jobOffer',
        points: null,
        maxPoints: null,
        pointImprovement,
        improvement: jobOffer,
        forSpouse: false,
        difficulty: ImprovementDifficulty.jobOffer(jobOffer),
      }
    }
  }

  consolidate(
    profile: EditableProfile,
    points: PointCalculatorResult,
    suggestions: Suggestion[]
  ): Suggestion | null {
    if (suggestions.length) {
      return ListUtils.maxBy(suggestions, suggestion => suggestion.improvement)
    } else {
      return null
    }
  }

  applySuggestion(profile: EditableProfile, suggestion: Suggestion) {
    profile.jobOffer = suggestion.improvement
  }
}
