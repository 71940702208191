
import Vue from 'vue'
import Component from 'vue-class-component'
import UiUtils from '@/ui/util/ui-utils'

import PoolStats from './PoolStats.vue'
import ImprovementGuide from './improvement-guide/ImprovementGuide.vue'
import IrccGuide from './ircc-guide/IrccGuide.vue'
import RouteNames from '@/ui/router/route-names'

@Component({
  head: {
    title: UiUtils.translateTitle('label.pool'),
  },
  components: {
    PoolStats,
    ImprovementGuide,
    IrccGuide,
  },
})
export default class PoolScreen extends Vue {
  mounted() {
    UiUtils.scrollByRouteParams(this)
  }

  goToNextStep() {
    this.$router.push({ name: RouteNames.GUIDE_ITA })
  }
}
