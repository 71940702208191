import NumericImprovementCalculator from './util/numeric-improvement-calculator'
import ImprovementFieldId from '../common/improvement-field-id'
import ImprovementDifficulty from './util/improvement-difficulty'

export default class WorkExpImprovementCalculator extends NumericImprovementCalculator {
  constructor(forSpouse?: boolean) {
    super({
      profileField: 'workExp',
      category: forSpouse ? 'categoryB' : 'categoryA',
      forSpouse: !!forSpouse,
      difficultyMap: ImprovementDifficulty.workExp,
      minValue: 1,
      maxValue: 5,
    })
  }

  get fieldId(): ImprovementFieldId {
    return this.params.forSpouse ? 'spouseWorkExp' : 'workExp'
  }
}
