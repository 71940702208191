import { EducationLevel } from 'shared-entities'
import ImprovementFieldId from '../common/improvement-field-id'
import NumericImprovementCalculator from './util/numeric-improvement-calculator'
import ImprovementDifficulty from './util/improvement-difficulty'

export default class EducationImprovementCalculator extends NumericImprovementCalculator {
  constructor(forSpouse?: boolean) {
    super({
      profileField: 'education',
      category: forSpouse ? 'categoryB' : 'categoryA',
      forSpouse: !!forSpouse,
      difficultyMap: ImprovementDifficulty.education,
      minValue: EducationLevel.SECONDARY,
      maxValue: EducationLevel.PHD,
    })
  }

  get fieldId(): ImprovementFieldId {
    return this.params.forSpouse ? 'spouseEducation' : 'education'
  }
}
