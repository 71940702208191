import FieldImprovementCalculator from '../../common/field-improvement-calculator'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCalculator, { PointCalculatorResult } from '@/data/point-calculator/point-calculator'
import Suggestion from '../../common/suggestion'
import { Profile } from 'shared-entities'
import ImprovementFieldId from '../../common/improvement-field-id'
import ListUtils from '@/common/util/list-utils'

/**
 * Maps profile field values to difficulty.
 * Each difficulty should be set relative to the closest lower value.
 *
 * For example, a DifficultyMap of { 1: 40, 3: 100 } means that
 * the difficulty of improving the field
 * from 0 to 1 is 40, from 1 to 3 is 100, from 0 to 3 is 140.
 */
export interface DifficultyMap {
  [key: number]: number
}

/**
 * Constructor parameters.
 */
interface Params {
  /** The profile field that the calculator will attempt to improve. */
  profileField: keyof Profile
  /** The point category to which the profile field belongs. */
  category: 'categoryA' | 'categoryB' | 'categoryC' | 'categoryD'
  /** If true, then the spouse's profile object will be used for improvement. */
  forSpouse: boolean
  /** The difficulty map for this improvement. */
  difficultyMap: DifficultyMap
  /** Minimum field value that gives a non-zero CRS score. */
  minValue: number
  /** The maximum field value to which the field can be set to improve the CRS score. */
  maxValue: number
}

/**
 * A generic improvement calculator for numeric profile fields where increasing values
 * mean increasing CRS points.
 */
export default abstract class NumericImprovementCalculator implements FieldImprovementCalculator {
  constructor(protected readonly params: Params) {}

  abstract fieldId: ImprovementFieldId

  calculate(profile: EditableProfile, points: PointCalculatorResult): Suggestion | null {
    if (this.params.forSpouse && !profile.hasSpouse) {
      return null
    }

    const currentValue = this.getProfileValue(profile)
    if (currentValue) {
      let suggestion: Suggestion | null = null
      let increment = 1
      while (currentValue + increment <= this.params.maxValue) {
        suggestion = this.createSuggestion(currentValue + increment, profile, points)
        increment++
      }

      return suggestion
    } else {
      return this.createSuggestion(this.params.minValue, profile, points)
    }
  }

  private getProfileValue(profile: EditableProfile): number {
    return this.params.forSpouse
      ? (profile.spouse[this.params.profileField] as number)
      : (profile[this.params.profileField] as number)
  }

  private setProfileValue(profile: EditableProfile, value: number) {
    const theProfile = (this.params.forSpouse ? profile.spouse : profile) as any
    theProfile[this.params.profileField] = value
  }

  private createSuggestion(
    value: number,
    profile: EditableProfile,
    points: PointCalculatorResult
  ): Suggestion | null {
    const improvedProfile = profile.clone() as any
    this.setProfileValue(improvedProfile, value)
    const improvedPoints = PointCalculator.calculatePoints(improvedProfile)

    const pointImprovement = improvedPoints.totalPoints - points.totalPoints
    if (pointImprovement <= 0) {
      return null
    } else {
      const category = (points[this.params.category] as any)[this.params.profileField]

      return {
        fieldId: this.fieldId,
        points: category ? category.points : null,
        maxPoints: category ? category.maxPoints : null,
        pointImprovement: improvedPoints.totalPoints - points.totalPoints,
        difficulty: this.calculateDifficulty(profile, value),
        improvement: value,
        forSpouse: this.params.forSpouse,
      }
    }
  }

  private calculateDifficulty(profile: EditableProfile, improvement: number): number {
    let difficulty = 0
    const currentValue = this.getProfileValue(profile)
    let current = (currentValue || this.params.minValue - 1) + 1
    while (current <= improvement && current <= this.params.maxValue) {
      difficulty += this.params.difficultyMap[current]
      current++
    }
    return difficulty
  }

  consolidate(
    profile: EditableProfile,
    points: PointCalculatorResult,
    suggestions: Suggestion[]
  ): Suggestion | null {
    const maxSuggestion = ListUtils.maxBy(suggestions, suggestion => suggestion.improvement)
    if (maxSuggestion) {
      const maxImprovement = maxSuggestion.improvement as number
      return this.createSuggestion(maxImprovement, profile, points)
    }
    return null
  }

  applySuggestion(profile: EditableProfile, suggestion: Suggestion) {
    const improvement = suggestion.improvement
    if (improvement) {
      this.setProfileValue(profile, improvement)
    }
  }
}
