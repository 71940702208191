
import Vue from 'vue'
import Component from 'vue-class-component'

import SuggestionGenerator from '@/data/suggestions/suggestion-generator'
import store from '@/store/store'
import SuggestionGroup, { SuggestionContainer } from '@/data/suggestions/common/suggestion-group'

import ImprovementGuideSettings from './ImprovementGuideSettings.vue'
import ImprovementGuideSuggestion from './ImprovementGuideSuggestion.vue'
import PointCategoryHeader from '../../common/PointCategoryHeader.vue'
import Suggestion from '@/data/suggestions/common/suggestion'
import PointCalculator from '@/data/point-calculator/point-calculator'
import { SuggestionFilter } from '@/data/suggestions/common/suggestion-filter'

interface SuggestionElement {
  id: any
  type: 'header' | 'suggestion'
  value: SuggestionContainer | Suggestion
  isLast?: boolean
}

/**
 * Displays improvement guide settings and improvement suggestions.
 */
@Component({
  components: {
    PointCategoryHeader,
    ImprovementGuideSettings,
    ImprovementGuideSuggestion,
  },
})
export default class ImprovementGuide extends Vue {
  suggestions: SuggestionElement[] | null = null
  message = ''

  private targetPoints: number | null = null
  private filters: SuggestionFilter[] | null = null

  onTargetChange(target: number) {
    this.targetPoints = target
    this.generateSuggestions()
  }

  onFiltersChange(filters: SuggestionFilter[]) {
    this.filters = filters
    this.generateSuggestions()
  }

  private generateSuggestions() {
    const targetPoints = this.targetPoints
    const filters = this.filters
    if (targetPoints && filters && targetPoints <= PointCalculator.MAX_CRS_POINTS) {
      const suggestionResult = SuggestionGenerator.generate(
        store.state.profile.profile,
        targetPoints,
        filters
      )

      this.suggestions = []
      suggestionResult.suggestionGroups.forEach(group => {
        this.suggestions!.push({
          id: group.title,
          type: 'header',
          value: group,
        })

        Array.prototype.push.apply(
          this.suggestions,
          group.suggestions.map((suggestion, index) => {
            return {
              id: suggestion.fieldId,
              type: 'suggestion',
              value: suggestion,
              isLast: index === group.suggestions.length - 1,
            }
          })
        )
      })

      if (suggestionResult.improvedPoints.totalPoints < targetPoints) {
        this.message = 'Cannot achieve target points with current settings'
      } else {
        this.message = ''
      }
    }
  }
}
