
import Vue from 'vue'
import Component from 'vue-class-component'
import { ProfileTypes } from '@/store/modules/profile.module'
import PoolDataRepository, { PoolDataModel } from '@/data/repository/pool-data.repository'
import RouteNames from '@/ui/router/route-names'

/**
 * Displays the current CRS points and latest invitation round stats.
 */
@Component
export default class PoolStats extends Vue {
  latestRoundPoints = ''
  invitationCount = ''
  isPoolDataLoading = true
  subscriberId: number | null = null

  mounted() {
    this.subscriberId = PoolDataRepository.subscribeToPoolData(this.onPoolDataUpdated)
  }

  beforeDestroy() {
    if (this.subscriberId) {
      PoolDataRepository.unsubscribeFromPoolData(this.subscriberId)
    }
  }

  private onPoolDataUpdated(poolData: PoolDataModel | null) {
    if (poolData) {
      this.isPoolDataLoading = false
      this.latestRoundPoints =
        poolData.latestRound.lowestScore + (poolData.latestRound.program ? '*' : '')
      this.invitationCount = poolData.latestRound.numberOfInvitations.toString()
    }
  }

  get totalPoints(): number {
    return this.$store.getters[ProfileTypes.getters.totalPoints]
  }

  goToRounds() {
    this.$router.push({ name: RouteNames.ROUNDS })
  }
}
