
import Vue from 'vue'
import Component from 'vue-class-component'
import { StepViewItem, StepViewUpdate } from '@/ui/components/step-view/step-view-item'
import { Prop, Watch } from 'vue-property-decorator'
import dayjs, { Dayjs, OpUnitType } from 'dayjs'

import DaysLeft from './DaysLeft.vue'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import { GuideProgress, GuideName } from 'shared-entities'
import GuideDeadlineCalculator from '@/data/guide/guide-deadline-calculator'

export interface StepUpdate {
  stepId: number
  value: boolean
}

@Component({
  components: {
    DaysLeft,
  },
})
export default class GuideTodoList extends Vue {
  @Prop({ type: String, required: true })
  title!: string
  @Prop({ type: String, required: true })
  dateTitle!: string

  @Prop({ type: String, required: true })
  guideName!: GuideName
  @Prop({ type: Array, required: true })
  allSteps!: StepViewItem[]

  @Prop({ type: Object, required: true })
  minDate!: Dayjs
  @Prop({ type: Object, required: true })
  maxDate!: Dayjs

  private initialGuideDate: Dayjs | null = null

  mounted() {
    this.onGuideDateUpdated()
  }

  get date(): Dayjs | null {
    return ProfileModuleUtils.getGuideDate(this.guideName)
  }

  get computedMinDate(): Dayjs {
    // Select between the passed minDate and the guide date so that the guide date is never
    // out of range.
    const guideDate = this.initialGuideDate
    if (guideDate && guideDate.isBefore(this.minDate)) {
      return guideDate
    }
    return this.minDate
  }

  get computedMaxDate(): Dayjs {
    const guideDate = this.initialGuideDate
    if (guideDate && guideDate.isAfter(this.maxDate)) {
      return guideDate
    }
    return this.maxDate
  }

  get steps(): StepViewItem[] {
    const progress = ProfileModuleUtils.getGuideStepProgress(this.guideName)

    return this.allSteps.map(step => {
      return {
        ...step,
        subItems: step.subItems.map(subItem => {
          const stepProgress = progress[step.id] || {}
          return {
            ...subItem,
            isChecked: !!stepProgress[subItem.id],
          }
        }),
      }
    })
  }

  get daysLeft(): number | null {
    return GuideDeadlineCalculator.calculateDaysLeft(this.guideName)
  }

  onDateInput(date: Dayjs) {
    ProfileModuleUtils.setGuideDate(this.guideName, date)
  }

  onSubItemToggled({ stepId, subItemId, value }: StepViewUpdate) {
    ProfileModuleUtils.setGuideStepValue(this.guideName, stepId, subItemId, value)
  }

  showDateExplanation() {
    this.$emit('show-date-explanation')
  }

  @Watch('date')
  private onGuideDateUpdated() {
    if (!this.initialGuideDate) {
      this.initialGuideDate = this.date
    }
  }
}
