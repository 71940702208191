import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Suggestion from '@/data/suggestions/common/suggestion'
import I18n from '@/i18n/i18n'

export default function(profile: EditableProfile, suggestion: Suggestion): string {
  const years = suggestion.improvement as number
  return (
    `Receive a Canadian post-secondary credential of ` +
    `<strong>${I18n.$tc('plural.year', years, { count: years })} or longer</strong>.`
  )
}
