
import Vue from 'vue'
import Component from 'vue-class-component'
import Suggestion from '@/data/suggestions/common/suggestion'
import { Prop, Watch } from 'vue-property-decorator'
import store from '@/store/store'
import SuggestionFormatter from './suggestion-formatters/suggestion-formatter'

/**
 * Renders a single improvement suggestion.
 * @param {Suggestion} suggestion the suggestion.
 */
@Component
export default class ImprovementGuideSuggestion extends Vue {
  @Prop({ required: true })
  suggestion!: Suggestion

  title: string = ''
  description: string = ''

  mounted() {
    this.onSuggestionChanged()
  }

  get isNegative(): boolean {
    return this.suggestion.pointImprovement < 0
  }

  @Watch('suggestion')
  private onSuggestionChanged() {
    this.positionCaret()
    this.formatSuggestion()
  }

  private formatSuggestion() {
    const suggestion = this.suggestion
    this.description = SuggestionFormatter.format(store.state.profile.profile, suggestion)
    this.title = this.$ts('label.' + suggestion.fieldId)
  }

  private getImprovement(): HTMLElement | undefined {
    return this.$refs.improvement as HTMLElement
  }

  private getCaret(): HTMLElement | undefined {
    return this.$refs.caret as HTMLElement
  }

  private positionCaret() {
    this.$nextTick(() => {
      const improvement = this.getImprovement()
      const caret = this.getCaret()
      if (improvement && caret) {
        caret.style.left = improvement.offsetLeft + improvement.clientWidth / 2 + 'px'
      }
    })
  }
}
