
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/18.png'),
      w: 805,
      h: 725,
      title: '1. Go to your completed Express Entry profile page. Click Continue.',
    },
  ]
  images2: GalleryImage[] = [
    {
      src: require('./img/18-1.png'),
      w: 805,
      h: 675,
      title: '2. Complete your Declaration and Electronic Signature. CLick Sign.',
    },
    {
      src: require('./img/18-1-1.png'),
      w: 805,
      h: 725,
      title: '2. Complete your Declaration and Electronic Signature. CLick Sign.',
    },
  ]
  images3: GalleryImage[] = [
    {
      src: require('./img/18-2.png'),
      w: 805,
      h: 725,
      title: '3. Confirm your Signature. Click Transmit.',
    },
    {
      src: require('./img/18-2-1.png'),
      w: 805,
      h: 705,
      title: '3. Confirm your Signature. Click Transmit.',
    },
  ]
  images4: GalleryImage[] = [
    {
      src: require('./img/18-3.png'),
      w: 800,
      h: 674,
      title: '4. You have successfully submitted your Express Entry profile.',
    },
  ]
  images5: GalleryImage[] = [
    {
      src: require('./img/18-4.png'),
      w: 805,
      h: 470,
      title: '5. Check your Express Entry profile status. Click Check full application status.',
    },
  ]
  images6: GalleryImage[] = [
    {
      src: require('./img/18-5.png'),
      w: 805,
      h: 510,
      title: '6. Your express entry profile status details.',
    },
  ]
}
