import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Suggestion from '@/data/suggestions/common/suggestion'
import I18n from '@/i18n/i18n'

export default function(profile: EditableProfile, suggestion: Suggestion): string {
  const prefix = suggestion.forSpouse ? 'Have your spouse get' : 'Get'
  const years = suggestion.improvement as number
  return (
    `${prefix} at least <strong>${I18n.$tc('plural.year', years, { count: years })}</strong> ` +
    'of Canadian work experience.'
  )
}
