import BooleanImprovementCalculator from './util/boolean-improvement-calculator'
import ImprovementFieldId from '../common/improvement-field-id'
import ImprovementDifficulty from './util/improvement-difficulty'

export default class CoqImprovementCalculator extends BooleanImprovementCalculator {
  fieldId: ImprovementFieldId = 'coq'

  constructor() {
    super({
      category: 'categoryC',
      profileField: 'hasCert',
      difficulty: ImprovementDifficulty.coq,
    })
  }
}
