import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Suggestion from '@/data/suggestions/common/suggestion'
import { NocGroup } from 'shared-entities'

export default function(profile: EditableProfile, suggestion: Suggestion): string {
  const jobOffer = suggestion.improvement as NocGroup
  switch (jobOffer) {
    case NocGroup.NOC_00:
      return 'Get a job offer in NOC 00'
    case NocGroup.NOC_0:
      return 'Get a job offer in one of the NOCs 0, A or B'
    default:
      return ''
  }
}
