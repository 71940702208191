import BooleanImprovementCalculator from './util/boolean-improvement-calculator'
import ImprovementFieldId from '../common/improvement-field-id'
import ImprovementDifficulty from './util/improvement-difficulty'

export default class PnpImprovementCalculator extends BooleanImprovementCalculator {
  fieldId: ImprovementFieldId = 'pnp'

  constructor() {
    super({
      profileField: 'hasProvNomination',
      category: 'categoryD',
      difficulty: ImprovementDifficulty.pnp,
    })
  }
}
