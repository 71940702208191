import Suggestion from '@/data/suggestions/common/suggestion'
import {
  LangImprovement,
  LangScoreImprovement,
} from '@/data/suggestions/improvements/language-improvement-calculator'
import I18n from '@/i18n/i18n'
import { LanguageSkill, LanguageTestType } from 'shared-entities'
import { LANGUAGE_TESTS_MAP } from '@/data/entity/profile/language-test'
import Utils from '@/common/util/utils'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'

type Lang = 'first' | 'second'

export default function(profile: EditableProfile, suggestion: Suggestion): string {
  const forSpouse = suggestion.forSpouse
  const improvement = suggestion.improvement as LangImprovement

  let description = ''
  if (improvement.firstLangTestType) {
    Utils.assert(improvement.firstLangScores, 'firstLangScores must be defined')
    description = getMissingTestDescription('first', improvement.firstLangScores!, forSpouse)
  } else if (improvement.firstLangScores && profile.firstLangTestType) {
    description = formatImprovementDescription(
      profile.firstLangTestType,
      improvement.firstLangScores,
      forSpouse
    )
  }

  if (improvement.firstLangScores && improvement.secondLangScores) {
    description += '<br />'
  }

  if (improvement.secondLangTestType) {
    Utils.assert(improvement.secondLangScores, 'secondLangScores must be defined')
    description += getMissingTestDescription('second', improvement.secondLangScores!, forSpouse)
  } else if (improvement.secondLangScores && profile.secondLangTestType) {
    description += formatImprovementDescription(
      profile.secondLangTestType,
      improvement.secondLangScores,
      forSpouse
    )
  }

  return description
}

function getMissingTestDescription(
  lang: Lang,
  scores: LangScoreImprovement,
  forSpouse: boolean
): string {
  const spouseString = forSpouse ? "spouse's " : ''
  const result = `<p>You have not selected your ${spouseString}${lang} official language test.`
  const skills = Utils.typedEntries(scores)
  if (!skills.length) {
    throw new Error('Invalid score improvement: no skill improvement is set')
  }

  if (skills.length > 1) {
    return (
      result +
      ' Pass an exam with the following minimum CLB scores:' +
      formatScoreImprovement(skills)
    )
  } else {
    return result + ` Pass an exam with a score of at least CLB ${skills[0][1]}`
  }
}

function formatImprovementDescription(
  testType: LanguageTestType,
  scores: LangScoreImprovement,
  forSpouse: boolean
): string {
  const spouseString = forSpouse ? "spouse's " : ''
  const skills = Utils.typedEntries(scores)
  if (!skills.length) {
    throw new Error('Invalid score improvement: no skill improvement is set')
  }

  if (skills.length > 1) {
    return (
      `Improve your ${spouseString}${testType} scores:` + formatScoreImprovement(skills, testType)
    )
  } else {
    const [skill] = skills[0]
    return (
      `Improve your ${spouseString}${testType} <strong>${translateSkillLabel(skill)}</strong> ` +
      `score to at least ${formatScoreImprovement(skills, testType)}.`
    )
  }
}

function formatScoreImprovement(
  skills: [LanguageSkill, number | undefined][],
  testType?: LanguageTestType
): string {
  if (!skills.length) {
    throw new Error('Invalid score improvement: no skill improvement is set')
  }

  if (skills.length > 1) {
    const skillsList = skills
      .sort(([skillA], [skillB]) => (skillA < skillB ? -1 : 1))
      .map(([skill, score]) => {
        const formattedScore = testType ? convertClbScore(score!, skill, testType) : score
        return (
          `<li><strong>${translateSkillLabel(skill)}</strong> at least ` +
          `<strong>${formattedScore}</strong></li>`
        )
      })
      .join('')
    return `<ul class="mt-1">${skillsList}</ul>`
  } else {
    const [skill, score] = skills[0]
    const formattedScore = testType ? convertClbScore(score!, skill, testType) : score
    return `<strong>${formattedScore}</strong>`
  }
}

function translateSkillLabel(skill: LanguageSkill): string {
  return I18n.$t(`profile.language.skills.${skill}`).toLowerCase()
}

function convertClbScore(score: number, skill: LanguageSkill, testType: LanguageTestType): number {
  return LANGUAGE_TESTS_MAP[testType].convertClbScore(score, skill)
}
