
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/15-16.png'),
      w: 811,
      h: 726,
      title: '1. Go to your Express Entry profile page.',
    },
  ]
  images2: GalleryImage[] = [
    {
      src: require('./img/17-1.png'),
      w: 807,
      h: 723,
      title: '2. Complete Personal details.',
    },
  ]
  images3: GalleryImage[] = [
    {
      src: require('./img/17-2.png'),
      w: 805,
      h: 535,
      title: '3. Complete Contact details.',
    },
  ]
  images4: GalleryImage[] = [
    {
      src: require('./img/17-3.png'),
      w: 805,
      h: 536,
      title: '4. Complete Study and languages.',
    },
  ]
  images5: GalleryImage[] = [
    {
      src: require('./img/17-4.png'),
      w: 805,
      h: 535,
      title: '5. Complete Applications details.',
    },
  ]
  images6: GalleryImage[] = [
    {
      src: require('./img/17-5.png'),
      w: 805,
      h: 495,
      title: '6. Complete Representative details.',
    },
  ]
  images7: GalleryImage[] = [
    {
      src: require('./img/17-6.png'),
      w: 805,
      h: 652,
      title: '7. Complete Work history.',
    },
  ]
}
