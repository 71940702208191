
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/13.png'),
      w: 805,
      h: 715,
      title:
        "1. Go to your IRCC account's home page. Navigate to Start an application. Click Apply to come to Canada.",
    },
  ]
  images2: GalleryImage[] = [
    {
      src: require('./img/14.png'),
      w: 805,
      h: 680,
      title:
        '2. Navigate to Determine your eligibility and apply online. Click Express Entry (EE).',
    },
  ]
  images3: GalleryImage[] = [
    {
      src: require('./img/15.png'),
      w: 808,
      h: 438,
      title: '3. Complete questionnaire to find out if your are eligible to apply.',
    },
  ]
  images4: GalleryImage[] = [
    {
      src: require('./img/15-13.png'),
      w: 812,
      h: 512,
      title: '4. Based on your answers check your results. Click continue',
    },
  ]
  images5: GalleryImage[] = [
    {
      src: require('./img/15-14.png'),
      w: 806,
      h: 593,
      title: '5. Complete questionnaire.',
    },
  ]
  images6: GalleryImage[] = [
    {
      src: require('./img/15-16.png'),
      w: 811,
      h: 726,
      title: '6. Your express entry profile is created.',
    },
  ]
}
