
import Vue from 'vue'
import Component from 'vue-class-component'
import dayjs, { Dayjs } from 'dayjs'
import { Watch } from 'vue-property-decorator'

import ALL_STEPS from './ircc-guide-steps'

import GalleryImage from '@/common/plugins/gallery/gallery-image'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import { StepViewItem } from '@/ui/components/step-view/step-view-item'
import GuideTodoList, { StepUpdate } from '../../common/GuideTodoList.vue'

@Component({
  components: {
    GuideTodoList,
  },
})
export default class IrccGuide extends Vue {
  minDate = dayjs().subtract(2, 'year')
  maxDate = dayjs()

  allSteps = ALL_STEPS

  showDateExplanation() {
    this.$dialog.alert({
      title: 'Express Entry profile submission date',
      message:
        'Your Express Entry profile will expire ONE year from the date you submit, OR when ' +
        "you're invited to apply OR when you withdraw your profile — whichever comes first.",
    })
  }
}
