import { StepViewItem } from '@/ui/components/step-view/step-view-item'
import { GuideStepGroupId } from 'shared-entities'

const steps: StepViewItem[] = [
  {
    id: GuideStepGroupId.IrccAccount,
    title: 'Account',
    subItems: [
      {
        id: 100,
        title: 'GCKey sign up',
        component: require('./steps/IrccStepSignup.vue').default,
      },
      {
        id: 101,
        title: 'Complete account sign up',
        component: require('./steps/IrccStepSignupComplete.vue').default,
      },
    ],
  },
  {
    id: GuideStepGroupId.ExpressEntryProfile,
    title: 'Express Entry profile',
    subItems: [
      {
        id: 200,
        title: 'Eligibility questions',
        component: require('./steps/IrccStepEligiblityQuestions.vue').default,
      },
      {
        id: 201,
        title: 'Complete your Express Entry profile',
        component: require('./steps/IrccStepEEProfileComplete.vue').default,
      },
      {
        id: 202,
        title: 'Submit your Express Entry profile',
        component: require('./steps/IrccStepEEProfileSubmit.vue').default,
      },
    ],
  },
]

export default steps
