
import Vue from 'vue'
import Component from 'vue-class-component'

import GalleryImage from '@/common/plugins/gallery/gallery-image'

@Component
export default class IrccStepRegister extends Vue {
  images1: GalleryImage[] = [
    {
      src: require('./img/3.png'),
      w: 1240,
      h: 638,
      title: '1. Go to the IRCC signin page. Click Sign Up',
    },
  ]
  images2: GalleryImage[] = [
    {
      src: require('./img/4.png'),
      w: 1218,
      h: 719,
      title: '2. Read the Terms and Conditions of Use. Click I Accept.',
    },
  ]
  images3: GalleryImage[] = [
    {
      src: require('./img/5.png'),
      w: 1244,
      h: 715,
      title: '3. Create a username. Click Continue.',
    },
  ]
  images4: GalleryImage[] = [
    {
      src: require('./img/6.png'),
      w: 1125,
      h: 721,
      title: '4. Create a password. Click Continue.',
    },
  ]
  images5: GalleryImage[] = [
    {
      src: require('./img/7.png'),
      w: 809,
      h: 656,
      title: '5. Create your security questions and responses. Click Continue.',
    },
  ]
  images6: GalleryImage[] = [
    {
      src: require('./img/8.png'),
      w: 1188,
      h: 490,
      title: '6. Click Continue to complete account sign up.',
    },
  ]
}
