import { GuideDateUpdate, GuideStepUpdate } from '@/data/entity/guide/guide-update'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { StatusCenterStepUpdate } from '@/data/entity/status-center/status-center-update.entity'
import dayjs, { Dayjs } from 'dayjs'
import {
  CecFields,
  EligibilityFields,
  FstFields,
  FswFields,
  GuideProgress,
  GuideSubSteps,
} from 'shared-entities'
import store from '../store'
import { ProfileTypes } from './profile.module'

export default class ProfileModuleUtils {
  /**
   * Set the current profile's field to the given value.
   */
  static setProfileField<T extends keyof EditableProfile>(field: T, value: EditableProfile[T]) {
    store.commit(ProfileTypes.mutations.setProfileField, { field, value })
  }

  /**
   * Get the current profile's field.
   */
  static getProfileField<T extends keyof EditableProfile>(field: T): EditableProfile[T] {
    return store.getters[ProfileTypes.getters.getProfileField](field)
  }

  /**
   * Set the current profile's spouse's field to the given value.
   */
  static setSpouseField<T extends keyof EditableProfile>(field: T, value: EditableProfile[T]) {
    store.commit(ProfileTypes.mutations.setSpouseField, { field, value })
  }

  /**
   * Get the current profile's spouse's field.
   */
  static getSpouseField<T extends keyof EditableProfile>(field: T): EditableProfile[T] {
    return store.getters[ProfileTypes.getters.getSpouseField](field)
  }

  /**
   * Get the current profile's eligibility field
   */
  static getEligibilityField<T extends keyof EligibilityFields>(field: T): EligibilityFields[T] {
    return this.getProfileField('eligibility')[field]
  }

  /**
   * Set the current profile's eligibility field
   */
  static setEligibilityField<T extends keyof EligibilityFields>(
    field: T,
    value: EligibilityFields[T]
  ) {
    store.commit(ProfileTypes.mutations.setEligibilityField, { field, value })
  }

  static getCecField<T extends keyof CecFields>(field: T): CecFields[T] {
    return this.getProfileField('eligibility').cec[field]
  }

  static setCecField<T extends keyof CecFields>(field: T, value: CecFields[T]) {
    const program: keyof EligibilityFields = 'cec'
    store.commit(ProfileTypes.mutations.setEligibilityField, {
      program,
      field,
      value,
    })
  }

  static getFstField<T extends keyof FstFields>(field: T): FstFields[T] {
    return this.getProfileField('eligibility').fst[field]
  }

  static setFstField<T extends keyof FstFields>(field: T, value: FstFields[T]) {
    const program: keyof EligibilityFields = 'fst'
    store.commit(ProfileTypes.mutations.setEligibilityField, {
      program,
      field,
      value,
    })
  }

  static getFswField<T extends keyof FswFields>(field: T): FswFields[T] {
    return this.getProfileField('eligibility').fsw[field]
  }

  static setFswField<T extends keyof FswFields>(field: T, value: FswFields[T]) {
    const program: keyof EligibilityFields = 'fsw'
    store.commit(ProfileTypes.mutations.setEligibilityField, {
      program,
      field,
      value,
    })
  }

  static getGuideDate(guideName: keyof GuideProgress): Dayjs | null {
    const progress = store.state.profile.guideProgress[guideName]
    if (progress && progress.date) {
      return dayjs(progress.date)
    }
    return null
  }

  static getGuideStepProgress(guideName: keyof GuideProgress): GuideSubSteps {
    const progress = store.state.profile.guideProgress[guideName]
    if (progress) {
      return progress.steps
    }
    return {}
  }

  static setGuideDate(guideName: keyof GuideProgress, date: Dayjs) {
    const dateUpdate: GuideDateUpdate = {
      guideName,
      date,
    }
    store.commit(ProfileTypes.mutations.setGuideDate, dateUpdate)
  }

  static setGuideStepValue(
    guideName: keyof GuideProgress,
    groupId: number,
    stepId: number,
    value: boolean
  ) {
    const stepUpdate: GuideStepUpdate = {
      guideName,
      groupId,
      stepId,
      value,
    }
    store.commit(ProfileTypes.mutations.setGuideStepValue, stepUpdate)
  }

  static setStatusCenterStepValue(stepId: number, value: boolean) {
    const update: StatusCenterStepUpdate = {
      stepId,
      value,
    }
    store.commit(ProfileTypes.mutations.setStatusCenterStepValue, update)
  }
}
